import React from 'react'
import { LocalizedLink } from 'components/L10nLink'
import { injectIntl, intlShape } from 'react-intl'
import locales from '../../config/locales'
// import { getContext, compose } from 'recompose'
import { PageContextConsumer } from 'layouts/index'

const LangSwitcher = ({
  // pageContext: { originalPath = '', ...pageContext } = {},
  intl: { locale: current },
  display = 'code',
  activeClassName = 'active',
  linkClassName = '',
  listClassName = '',
  listItemClassName = '',
  ...props
}) => {
  return (
    <PageContextConsumer>
      {pageContext => {
        const { originalPath } = pageContext

        return (
          <div className={`lang-switcher menu ${props.className}`}>
            {locales.length && (
              <ul className={`list-reset m-0 ${listClassName}`}>
                {locales.map(({ code, ...locale }) => (
                  <li
                    key={code}
                    className={`lang-switcher-item lang-switcher-item-${code} ${
                      code === current ? activeClassName : ``
                    } ${listItemClassName}`}
                  >
                    <LocalizedLink
                      className={linkClassName}
                      intl={{ locale: code }}
                      to={originalPath}
                    >
                      {locale[display] || code}
                    </LocalizedLink>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )
      }}
    </PageContextConsumer>
  )
}

export default injectIntl(LangSwitcher)
