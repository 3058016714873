import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import withIntl from 'components/WithIntl'
import L10nLink from 'components/L10nLink'

const Navigation = withIntl(
  ({
    nav,
    intl,
    listClassName = ``,
    listItemClassName = ``,
    linkClassName = ``,
    ...props
  }) => (
    <nav {...props}>
      <ul className={listClassName}>
        {nav.edges.length &&
          nav.edges.map(({ node: { id, title, href } }) => (
            <li key={id} className={listItemClassName}>
              <L10nLink
                to={href}
                onClick={() => {
                  props.onClick && props.onClick()
                }}
                className={linkClassName}
              >
                {title[intl.locale]}
              </L10nLink>
            </li>
          ))}
      </ul>
    </nav>
  )
)

export default React.forwardRef((props, ref) => (
  <StaticQuery
    query={graphql`
      {
        nav: allNavigationYaml {
          edges {
            node {
              id
              title {
                bg
                en
              }
              href
            }
          }
        }
      }
    `}
    render={data => <Navigation {...props} {...data} ref={ref} />}
  />
))
