module.exports = [
  {
    code: 'bg',
    locale: 'bg',
    name: 'Български',
    default: true,
  },
  {
    code: 'en',
    locale: 'en',
    name: 'English',
  },
]
