import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { IntlProvider, addLocaleData, FormattedMessage } from 'react-intl'
import { getLocalizedPath } from 'components/L10nLink'
import { withContext } from 'recompose'
import Header from 'components/header'
import styled from 'styled-components'
import screens from 'styles/tailwind/screens'

import 'styles/main.css'

import locales from '../../config/locales'
import en from 'l10n/en.json'
import bg from 'l10n/bg.json'

// Locale data
import enData from 'react-intl/locale-data/en'
import bgData from 'react-intl/locale-data/bg'

addLocaleData([...enData, ...bgData])

const l10n = {
  en,
  bg: {
    ...en,
    ...bg,
  },
}

const {
  Consumer: PageContextConsumer,
  Provider: PageContextProvider,
} = React.createContext({})

export { PageContextProvider, PageContextConsumer }

const MainContainer = styled.div`
  min-height: calc(100vh - 50px);

  @media (min-width: ${screens.md}) {
    min-height: calc(100vh - 90px);
  }
`

const Layout = ({ children, location, pageContext }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `}
    render={data => {
      const { lang, originalPath } = pageContext
      const startYear = 2002
      const dateNow = new Date()
      const yearNow = dateNow.getFullYear()
      const copyYears =
        yearNow > startYear ? `${startYear} - ${yearNow}` : yearNow

      return (
        <PageContextProvider value={pageContext}>
          <IntlProvider locale={lang} messages={l10n[lang]}>
            <>
              <Helmet
                title={data.site.siteMetadata.title}
                meta={
                  [
                    // { name: 'description', content: 'Sample' },
                    // { name: 'keywords', content: 'sample, something' },
                  ]
                }
              >
                <html lang={lang} />
                {locales.length &&
                  locales.map(({ code, locale }) => {
                    if (code === lang) {
                      return null
                    }

                    const href = `${location.origin ||
                      data.site.siteMetadata.siteUrl}${getLocalizedPath({
                      path: originalPath,
                      code,
                    })}`

                    return (
                      <link
                        key={`hreflang-${code}`}
                        rel="alternate"
                        href={href}
                        hreflang={locale}
                      />
                    )
                  })
                }
              </Helmet>

              <Header
                location={location}
                className="fixed pin-t pin-l pin-r z-50"
                siteTitle={data.site.siteMetadata.title}
              />

              <MainContainer className="container flex flex-col relative bg-white">
                {children}

                <footer className="bg-primary p-3 sm:p-6 md:px-8 text-white flex flex-col flex-wrap justify-between items-stretch text-xs sm:text-sm sm:flex-row sm:items-start">
                  <div className="copy text-center">
                    Dixbg.com &copy; {copyYears}
                  </div>
                  <div className="credits text-center">
                    <FormattedMessage id="creditsCreatedBy" />{' '}
                    <a target="_blank" rel="noopener" title="Premium Brand Design" href="https://ralev.com/">
                      Ralev.com
                    </a>
                  </div>
                </footer>
              </MainContainer>
            </>
          </IntlProvider>
        </PageContextProvider>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
