import React, { forwardRef } from 'react'
import styled from 'styled-components'

const TogglerBar = styled(({ className: userClassName, ...props }) => (
  <span
    {...props}
    className={`block my-1 w-full h-1 bg-black ${userClassName}`}
  />
))`
  transform-origin: 0 50%;
`

const Bar1 = styled(TogglerBar)`
  .open & {
    transform: rotate(45deg);
  }
`

const Bar2 = styled(TogglerBar)`
  .open & {
    opacity: 0;
  }
`

const Bar3 = styled(TogglerBar)`
  .open & {
    transform: rotate(-45deg) translateY(1px);
  }
`

const MenuToggler = forwardRef(({ className, ...props }, ref) => (
  <button
    {...props}
    ref={ref}
    className={`menu-toggler block px-2 w-10 h-12 focus:outline-none ${className}`}
  >
    <Bar1 />
    <Bar2 />
    <Bar3 />
  </button>
))

export default MenuToggler
