import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import SVGLogo from 'images/logo.svg'
import styled from 'styled-components'
import LangSwitcher from 'components/LangSwitcher'
import { withStateHandlers, lifecycle, compose } from 'recompose'
import MenuToggler from 'components/MenuToggler'
import screens from 'styles/tailwind/screens'
import colors from 'styles/tailwind/colors'
import Nav from 'components/MenuNav'

const LogoImg = styled(({ alt, ...props }) => (
  <img src={SVGLogo} alt={alt} {...props} />
))`
  width: 40px;

  @media (min-width: ${screens.md}) {
    width: 64px;
  }
`

const enhanceWithNavPopupStateHandlers = withStateHandlers(
  {
    navPopupOpen: false,
  },
  {
    toggleNavPopup: ({ navPopupOpen }) => (trueOrFalse = null) => ({
      navPopupOpen: trueOrFalse !== null ? trueOrFalse : !navPopupOpen,
    }),
  }
)

const enhanceWithDidUpdateLifecycle = lifecycle({
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.props.navPopupOpen) {
        this.props.toggleNavPopup && this.props.toggleNavPopup()
      }
    }
  },
})

const HeaderBase = ({
  siteTitle,
  navPopupOpen,
  toggleNavPopup,
  className,
  style,
}) => (
  <>
    <header className={className} style={style}>
      <div className="container flex items-center h-full md:px-6 bg-white shadow justify-between">
        <Link className="mx-2 flex items-center no-underline" to="/">
          <LogoImg alt={siteTitle} />{' '}
          <div className="uppercase font-bold ml-2 md:ml-4">DIXBG</div>
        </Link>

        <Nav
          className="hidden md:block ml-auto"
          listClassName="list-reset flex flex-row items-stretch m-0"
          linkClassName="no-underline text-black px-2 py-1 uppercase font-bold hover:text-primary focus:text-primary"
        />

        <LangSwitcher
          className="ml-auto mr-2 md:ml-2 md:mr-0"
          listClassName="block"
          listItemClassName="inline-block ml-1"
          linkClassName="block no-underline h-8 w-8 py-2 bg-grey-light rounded-full leading-none text-center text-sm uppercase align-middle text-black"
        />

        <MenuToggler
          className={`md:hidden ${navPopupOpen ? `open` : ``}`}
          onClick={() => toggleNavPopup()}
        />
      </div>
    </header>

    {navPopupOpen && (
      <div className="popup-nav-wrapper fixed flex pin items-stretch p-8 z-50 bg-white">
        <Nav
          className="block ml-auto flex-grow flex items-stretch"
          listClassName="list-reset flex-grow flex flex-col justify-center items-center"
          listItemClassName="block"
          onClick={() => toggleNavPopup(false)}
          linkClassName="no-underline text-black px-2 py-1 uppercase text-xl leading-loose font-bold hover:text-primary focus:text-primary"
        />
      </div>
    )}
  </>
)

HeaderBase.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderBase.defaultProps = {
  siteTitle: ``,
}

const Header = compose(
  enhanceWithNavPopupStateHandlers,
  enhanceWithDidUpdateLifecycle
)(HeaderBase)

const StyledHeader = styled(Header)`
  height: 50px;

  + .popup-nav-wrapper {
    margin-top: 50px;
  }

  @media (min-width: ${screens.md}) {
    height: 90px;

    + .popup-nav-wrapper {
      margin-top: 90px;
    }
  }

  .active > a {
    background-color: ${colors.primary} !important;
    color: #fff !important;
  }
`

export default StyledHeader
