import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { injectIntl } from 'react-intl'
import { find } from 'lodash'
import locales from '../../config/locales'
import { Location } from '@reach/router'
import { LayoutContextConsumer } from 'layouts/index'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { mapProps } from 'recompose'

const ScrollToLink = props => {
  const { to } = props

  return
}

export const getLocalizedPath = ({ path, code }) =>
  find(locales, { code }).default ? path : `/${code}${path}`

const LocalizedLink = React.forwardRef(
  ({ to, headerHeight = 0, intl: { locale }, ...props }, ref) => {
    const path = getLocalizedPath({
      path: to,
      code: locale,
    })

    return (
      <Location>
        {({ location: { pathname: currentPath } }) => {
          let linkPath

          if (to.startsWith('#')) {
            // linkPath = currentPath
            return (
              <AnchorLink
                ref={ref}
                {...props}
                offset={headerHeight}
                href={to}
              />
            )
          }

          return <GatsbyLink ref={ref} {...props} to={path} />
        }}
      </Location>
    )
  }
)

const Link = React.forwardRef(({ children, to, ...other }, ref) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^(#|\/(?!\/))/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <LocalizedLink ref={ref} to={to} {...other}>
        {children}
      </LocalizedLink>
    )
  }

  return (
    <a ref={ref} href={to} target="_blank" rel="noopener" {...other}>
      {children}
    </a>
  )
})

const MarkdownLink = mapProps(({ href, ...props }) => ({
  ...props,
  to: href,
  intl: {},
}))(Link)

export { LocalizedLink, MarkdownLink }

export default injectIntl(Link)
